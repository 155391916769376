import CONFIG from "../../SYSTEM/Config";
import moment from "moment";
const md5 = require('md5')

export const sendPostRequest = async (data, gateway = CONFIG.API_GATE) => {
    const response = await fetch(
        gateway,
        {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Accept': 'application/json'
            },
        }
    );

    const responseJson = await response.json();

    if (responseJson.status.result === 'SUCCESS') {
        return responseJson.data;
    } else {
        return false;
    }
}

export function createHash(number) {
    const str = moment().format('MM') + number + moment().format('DD')
    return md5(str)
}

export const isActive = (PRODUCT, SHOW_INACTIVE, MANAGER, SUPPLIER, SPEC_FILTER, SHOP, MARGE_FILTER, WRITE_FILTER, REPACK) => {
    if (typeof SUPPLIER == "undefined") {
        SUPPLIER = '';
    }

    if (typeof MANAGER == "undefined") {
        MANAGER = '';
    }

    if (parseInt(REPACK) > 0) {
        if (parseFloat(PRODUCT.real_amount) <= 0) {
            return false;
        }
        if (parseFloat(PRODUCT.sales_10) <= 0) {
            return false;
        }
        let pRepack = parseFloat(PRODUCT.real_amount) / parseFloat(PRODUCT.sales_10) * 10

        if (pRepack > parseInt(REPACK)) {
            return true;
        }

        return false;
    }

    if (parseInt(SPEC_FILTER.no_groups) == 1) {
        if (!PRODUCT.all_groups.length) {
            return true;
        } else {
            return false;
        }
    }

    if (parseInt(SPEC_FILTER.important) == 1) {
        if (parseInt(PRODUCT.important) == 0) {
            return false;
        }
    }

    if (parseInt(SPEC_FILTER.archive_only) == 1) {
        if (parseInt(PRODUCT.archive) == 1) {
            return true;
        } else {
            return false;
        }
    }


    let SHOW = true;
    let HARD_SHOW = false;

    if (SHOP.length) {
        if (typeof PRODUCT.shops !== "undefined") {
            if (!PRODUCT.shops.includes(SHOP)) {
                SHOW = false;
            }
        }
    }


    if (parseInt(PRODUCT.topack) == 1) {
        SHOW = false;
    }

    if (parseInt(SPEC_FILTER.topack) == 1 || SPEC_FILTER.topack == true) {
        if (parseInt(PRODUCT.topack) == 1) {
            return true;
        }
        return false;
    }

    // if (PRODUCT.seasonstart !== PRODUCT.seasonend) {
    //     if (parseInt(PRODUCT.today_season) == 1) {
    //         SHOW = false;
    //         if (parseInt(SPEC_FILTER.season) == 1 || SPEC_FILTER.season == true) {
    //             HARD_SHOW = true;
    //         }
    //     }
    // }
    if (parseInt(SPEC_FILTER.season) == 1 || SPEC_FILTER.season == true) {
        if (PRODUCT.seasonstart !== PRODUCT.seasonend) {
            if (parseInt(PRODUCT.today_season) == 1) {
                return true;
            }
        }
        return false;
    }

    if (parseInt(PRODUCT.archive) == 1) {
        SHOW = false;
        HARD_SHOW = false;

        // if (parseInt(SPEC_FILTER.archive) == 1 || SPEC_FILTER.archive == true) {
        //     if (parseInt(PRODUCT.availableones) > 0) {
        //         HARD_SHOW = true;
        //     }
        // }

        // if (parseInt(SPEC_FILTER.archive_all) == 1 || SPEC_FILTER.archive_all == true) {
        //     HARD_SHOW = true;
        // }
    }
    if (parseInt(SPEC_FILTER.archive) == 1 || SPEC_FILTER.archive == true) {
        if (parseInt(PRODUCT.archive) == 1) {
            if (parseInt(PRODUCT.availableones) > 0) {
                return true;
            }
        }

        return false;
    }

    if (parseInt(SPEC_FILTER.archive_all) == 1 || SPEC_FILTER.archive_all == true) {
        if (parseInt(PRODUCT.archive) == 1) {
            return true;
        }

        return false;
    }

    if (parseInt(PRODUCT.price == 0)) {
        SHOW = false;
    }

    if (parseInt(PRODUCT.availableones) == 0) {
        if (SHOW == true) {
            SHOW = false;
            if (parseInt(SPEC_FILTER.zeroes) == 1 || SPEC_FILTER.zeroes == true) {
                HARD_SHOW = true;
                SHOW = true;
            }
        }
    } else {
        if (parseInt(SPEC_FILTER.zeroes) == 1 || SPEC_FILTER.zeroes == true) {
            HARD_SHOW = false;
            SHOW = false;
        }
    }
    /*
        if (!HARD_SHOW && SHOW) {
            let spec_sum = 0;
            for (let i in SPEC_FILTER) {
                spec_sum += SPEC_FILTER[i]
            }
    
            let SPEC_CHECKER_AMOUNT = 0;
            if (parseInt(SPEC_FILTER.zeroes) == 1 || SPEC_FILTER.zeroes == true) {
                SPEC_CHECKER_AMOUNT = 1;
            }
            if (spec_sum > SPEC_CHECKER_AMOUNT) {
                SHOW = false;
            }
        }
    */
    if (parseInt(SPEC_FILTER.alls) == 1 || SPEC_FILTER.alls == true) {
        if (
            parseInt(PRODUCT.archive) == 0 &&
            parseInt(PRODUCT.topack) == 0
        ) {
            //if (PRODUCT.seasonstart == PRODUCT.seasonend || parseInt(PRODUCT.today_season) == 0) {
            HARD_SHOW = true;
            SHOW = true;
            //}
        }
    }



    if (MARGE_FILTER[0]) {
        if (parseInt(PRODUCT.topack) == 1) {
            return false;
        }
        if (MARGE_FILTER[0] > PRODUCT.marge || MARGE_FILTER[1] < PRODUCT.marge) {
            return false
        }
    }

    if (WRITE_FILTER[0]) {
        if (parseInt(PRODUCT.topack) == 1) {
            return false;
        }
        if (WRITE_FILTER[0] > PRODUCT.writeoffs || WRITE_FILTER[1] < PRODUCT.writeoffs) {
            return false
        }
    }

    if (SHOW || HARD_SHOW) {
        return filterManagerSupplier(PRODUCT, MANAGER, SUPPLIER);
    }
    return false;

}

function filterManagerSupplier(PRODUCT, MANAGER, SUPPLIER) {
    let SHOW = true;
    if (MANAGER.length) {
        if (PRODUCT.manager !== MANAGER) {
            SHOW = false;
        }
    }

    if (SUPPLIER.length) {
        if (PRODUCT.supplier !== SUPPLIER) {
            SHOW = false;
        }
    }

    if (SHOW) {
        return true;
    } else {
        return false;
    }
}